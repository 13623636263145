<template>
  <div class="h-full">
    <div
      class="p-2 border-bottom border-b border-gray-400 border-1 bg-gray-100"
    >
      <div class="relative pt-2 mx-auto mb-2 text-gray-600">
        <input
          class="w-full h-10 px-5 pr-16 text-sm bg-white border-gray-400 rounded border-1"
          type="search"
          v-model="searchTerm"
          v-debounce:1000ms="doSearch"
          name="search"
          placeholder="Buscar por nombre, apellido, productos, correo electrónico o ID de Pedido"
        />
        <button
          @click="doSearch()"
          type="submit"
          class="absolute top-0 right-0 mt-5 mr-4"
        >
          <svg
            class="w-4 h-4 text-gray-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>
    </div>
    <w-loading v-if="busy && resultsV2.length === 0"></w-loading>

    <div
      v-else
      class="w-full p-4 bg-white border border-gray-200 sm:p-8 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="flex items-center justify-between mb-4">
        <h5
          class="text-xl font-bold leading-none text-gray-900 dark:text-white"
        >
          Últimas ventas
        </h5>
        <!-- <a
          href="#"
          class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          View all
        </a> -->
      </div>
      <div class="flow-root">
        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
          <li
            :key="index"
            v-for="(item, index) in resultsV2"
            class="py-3 sm:py-4"
          >
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
                <img
                  class="w-8 h-8 rounded-full"
                  :src="`https://i.pravatar.cc/300?u=orsai-${index}`"
                  alt="Neil image"
                />
              </div>
              <div v-if="item.customer" class="flex-1 min-w-0">
                <p
                  class="text-sm font-medium text-gray-900 truncate dark:text-white"
                >
                  {{ item.customer.name }} {{ item.customer.lastName }}
                </p>
                <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                  {{ item.customer.email }}
                </p>
                <span
                  v-for="(itemCart, indexCart) in item.cart.filter(
                    (item) => item.sku
                  )"
                  :key="indexCart"
                  class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                  >{{ itemCart.qty }} x {{ itemCart.sku }}</span
                >
              </div>
              <div v-else class="flex-1 min-w-0">
                <p
                  class="text-sm font-medium text-gray-900 truncate dark:text-white"
                >
                  Venta en la tienda
                </p>

                <span
                  v-for="(itemCart, indexCart) in item.cart.filter(
                    (item) => item.sku
                  )"
                  :key="indexCart"
                  class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                  >{{ itemCart.qty }} x {{ itemCart.sku }}</span
                >
              </div>
              <div
                class="inline-flex items-center text-sm font-semibold text-gray-900 dark:text-white"
              >
                <div>
                  {{ (item.totalAmount * 0.5) | formatearDinero }}
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "reporteAutores",
  layout: "autores",
  data() {
    return {
      busy: false,
      loading: false,
      page: 0,
      skip: 0,
      limit: 10,
      loaded: false,
      searchTerm: null,
      resultsV2: [],
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (this.loaded) {
        console.log("running watcher", newValue, oldValue);
        this.doSearch();
      }
    },
    parametroSearch(newValue, oldValue) {
      // Si cambio el parametro de busqueda, limpio el filtro
      if (newValue != oldValue) {
        this.filter = "approved";
        this.searchTerm = newValue;
        this.page = 0;
        this.resultsV2 = [];
        this.clearAll();
        this.doSearch();
      }
    },
  },
  // mixins: [makeFindMixin({ service: "orders", watch: true })],
  async created() {
    await this.$store.dispatch("auth/authenticate");

    await this.showResults();
    this.loaded = true;
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("orders", { findResultsInStore: "find" }),
    results() {
      return this.resultsV2;
    },
    orderStatusQuery() {
      return { status: "paid" };
    },
    queryV2() {
      console.log("this.filter", this.filter);
      console.log("this.searchTerm", this.searchTerm);
      return {
        ...this.orderStatusQuery,
        $search: this.searchTerm,
        $sort: { createdAt: -1 },
        $skip: this.page * this.limit,
        $limit: this.limit,
        "cart.sku": {
          $in: [
            "CHA",
            "PIB",
            "RES",
            "ESP",
            "PAR",
            "MES",
            "INF",
            "ABU",
            "DES",
            "REN",
            "LOC",
            "COV",
            "PLA",
            "RLJ",
          ],
        },
      };
    },
    parametroSearch() {
      return this.$route.query.search;
    },
  },
  methods: {
    ...mapActions("orders", { findResults: "find" }),
    async showResults(extra) {
      this.busy = true;
      this.loading = true;
      let tmp = await this.findResults({
        query: { ...this.queryV2, ...extra },
      });
      this.resultsV2 = [...this.resultsV2, ...tmp.data];
      this.busy = false;
      this.loading = false;
    },
    more() {
      console.log("more");
      this.page++;
      this.showResults();
    },
    clear() {
      this.filter = "approved";
      this.searchTerm = "";
      this.page = 0;
      this.resultsV2 = [];
      this.clearAll();
      this.doSearch();
    },
    doSearch() {
      console.log("doSearch");
      this.page = 0;
      this.resultsV2 = [];
      this.showResults();
    },
  },
  filters: {
    formatearDinero(value) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
      }).format(value);
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  